import '/assets/styles/pages/campaign-detail.scss';

// Deps
import { useState, useEffect } from 'react'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';

// Partials
import Btn from '/views/partials/btn'
import Loader from '/views/partials/loader'
import Img from '@wearetla/tla-essentials-tools/partials/img';

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useParams } from '@wearetla/tla-essentials-tools/utilities/navigator';

// Services
import siteServices from '/services/site';

// Shared Endpoints
const getCampaign = (campaignID) => {
	return siteServices.getCampaign(campaignID)
}

const generateHead = (campaign) => {
	return {
		type: 'product',
		title: campaign.title,
		description: campaign.description,
		image: campaign.image ? campaign.image : false,
		// breadcrumbs: [{name: 'Kampanyalar', href: 'campaigns' }, { name: campaign.title }],
	}
}

const CampaignDetail = () => {
	const { id: paramCampaignID } = useParams('campaignDetail');

	const [campaign, setCampaign] = useState(false);
	const { setHead } = useHead();
	const { setNotFound } = useGlobalState();

	useEffect(() => {
		if(paramCampaignID && (!campaign || campaign.id.toString() !== paramCampaignID)) {
			getCampaign(paramCampaignID).then((payload) => {
				setCampaign(payload);
				setHead(generateHead(payload));
			}).catch((e) => {
				setNotFound();
			})
		}
	}, [campaign, paramCampaignID])

	return (
		<main className="page campaign-detail">
			<Breadcrumbs data={[{name: 'Kampanyalar', href: 'campaigns' }, (campaign ? { name: campaign.title } : undefined)]} />
			<div className="detail-wrap wrapper loader-container">
				<Loader loading={!campaign} />
				
				{campaign &&
					<div className="detail-content">
						<div className="content-left">
							<div className="content-imagewrap">
								<Img
									src={campaign.image}
									cover
									className="content-image" />
							</div>
						</div>
						<div className="content-right">
							<h1 className="content-title">{campaign.title}</h1>
							{(campaign.description && campaign.description !== campaign.title) &&
								<div
									className="content-description wysiwyg"
									dangerouslySetInnerHTML={{__html: campaign.description}} />
							}

							{campaign.startdate &&
								<p className="content-date">Kampanya Başlangıç Tarihi: <strong>{formatDate(campaign.startdate)}</strong></p>
							}
							{campaign.enddate &&
								<p className="content-date">Kampanya Bitiş Tarihi: <strong>{formatDate(campaign.enddate)}</strong></p>
							}

							{campaign.web_link &&
								<div className="content-controls">
									<Btn
										raw
										className="controls-cta pink"
										tag="link"
										href={campaign.web_link}>
										Ürünleri Gör
									</Btn>
								</div>
							}
						</div>
					</div>
				}
			</div>
		</main>
	)
}

export default CampaignDetail;